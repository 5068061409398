import Skeleton from '@mui/material/Skeleton';
import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { IStore } from '@interfaces/store';

import ProductListItemStyles from '@components/pages/productsList/ProductListItem/ProductListItem.styles';

interface IProductListItemSkeletonProps {
  slider?: boolean;
}

const ProductListItemSkeleton: React.FunctionComponent<IProductListItemSkeletonProps> = ({ slider = false }) => {
  const classes = ProductListItemStyles({ listSizeBig: true });
  const listSizeBig = useSelector((state: IStore) => state.page.listSizeBig);

  return (
    <div
      className={classNames(classes.productListItemContainer, {
        [classes.productListItemContainerSizeSmall]: listSizeBig && !slider,
        [classes.productListItemContainerSizeBig]: !listSizeBig && !slider,
        [classes.productListItemContainerSizeSlider]: slider,
      })}
    >
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '100%' }}>
        <Skeleton variant="rectangular" classes={{ root: classes.imageSkeleton }} animation={false} />
        <Skeleton animation={false} height={18} width="60%" />
        <Skeleton animation={false} height={19.7} width="60%" />
        <Skeleton animation={false} height={30} width="25%" />
        <Skeleton animation={false} height={22} width="70%" />
      </div>
    </div>
  );
};

export default ProductListItemSkeleton;
