import classNames from 'classnames';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { IResult } from 'ua-parser-js';

import Hidden from '@components/common/Hidden';
import ProductItem from '@components/common/products/ProductItem';
import SwiperComponent from '@components/common/Swiper';
import ProductListItemSkeleton from '@components/pages/productsList/ListLoading/ProductListItemSkeleton';
import { recommendedForProductParam } from '@constants/urlParams';
import { RecommendedForProductContext } from '@context/recomended';
import { IProductInList } from '@interfaces/product';
import { IStore } from '@interfaces/store';
import { generateArrayByItemsCount, generateProductUrl } from '@utils/common';
import { addParamsToUrl } from '../../../services/SSRService/utils';

import styles from './styles';

interface ISectionListProps {
  className?: string;
  items: IProductInList[];
  loaded?: boolean;
  showAddButton?: boolean;
  slidesPerView?: number;
  wrapperListName: string | JSX.Element;
}

const SectionList: React.FunctionComponent<ISectionListProps> = ({
  className,
  items,
  loaded,
  slidesPerView,
  showAddButton,
  wrapperListName,
}) => {
  const classes = styles();
  const productDetailSlug = useSelector((state: IStore) => state.pageData.data?.product_detail_page?.url);

  const { recommendedForProduct } = useContext(RecommendedForProductContext);

  const mapItems = (): JSX.Element[] => {
    return items?.filter((product) => !!product)?.map((product) => {
      const productId = product.colors[0]?.productId;
      const productUrl = generateProductUrl(product, productDetailSlug || '');
      const productUrlWithParams = recommendedForProduct ? addParamsToUrl(productUrl, { [recommendedForProductParam]: recommendedForProduct }) : productUrl;
      return (
        <ProductItem
          key={productId}
          item={product}
          productId={productId}
          productUrl={productUrlWithParams}
          showAddButton={showAddButton}
          wrapperListName={wrapperListName}
        />
      );
    });
  };

  const userAgent: Partial<IResult> | null = useSelector((state: IStore) => state.context.userAgent);
  const isMobile = userAgent?.device?.type && ['tablet', 'mobile'].includes(userAgent.device.type);
  const skeletonsArray = generateArrayByItemsCount(isMobile ? 1 : 5);

  const mapSkeletons = () => {
    return skeletonsArray.map((item, index) => (
      <div key={`${wrapperListName.toString()}-skeleton`+index}>
      <ProductListItemSkeleton slider={true}  />
      </div>
    ));
  };

  const itemsList = loaded && items ? mapItems() :mapSkeletons();

  return (
    <div className={classNames(classes.sectionList, className)}>
      <Hidden mdUp={true}>
        <SwiperComponent slides={itemsList} mobile={true} navigation={false} pagination={false} virtual={true} />
      </Hidden>
      <Hidden mdDown={true}>
        <SwiperComponent slides={itemsList} slidesPerView={slidesPerView} pagination={false} />
      </Hidden>
    </div>
  );
};

export default SectionList;
