import React from 'react';

export interface IRecommendedForProductContext {
  recommendedForProduct?: number | null;
}

const defaultValues: IRecommendedForProductContext = {
  recommendedForProduct: null,
};

export const RecommendedForProductContext = React.createContext<IRecommendedForProductContext>(defaultValues);
