import React from 'react';

import AddToCartWrap from '@components/common/buttons/AddToCart/WrapAddToCart';
import Link from '@components/common/Link';
import ProductFullResponsive from '@components/common/ProductFullResponsive';
import { PageTypes } from '@constants/pageTypes';
import { useTranslation } from '@hooks/useTranslation';
import { IProductInList } from '@interfaces/product';
import { createProductItemUsingData, getProductData, selectItem } from '@utils/gtag';
import productsWrapperStyles from './productsWrapper.styles';
import { useSelector } from 'react-redux';
import { IStore } from '@interfaces/store';
import { IUserState } from '@interfaces/account/reducer';

interface IProductItemProps {
  item: IProductInList;
  productId?: number;
  productUrl: string;
  showAddButton?: boolean;
  wrapperListName: string | JSX.Element;
}

const ProductItem: React.FunctionComponent<IProductItemProps> = ({
  item,
  productId,
  productUrl,
  showAddButton,
  wrapperListName,
}) => {
  const classes = productsWrapperStyles();
  const user: IUserState | null = useSelector((state: IStore) => state.account.user);

  const { t } = useTranslation();
  const onClickHandler = () => {
    sendGtagSelectItem(item);
  };

  const sendGtagSelectItem = (product) => {
    const userId = user?.data?.attributes?.email;
    const productData = getProductData(product, item.colors[0]);
    productData.listName = wrapperListName;
    selectItem(userId, createProductItemUsingData(productData));
  };

  return (
    <Link href={productUrl} pageType={PageTypes.productDetail} className={classes.productImageContainerLink} onClick={onClickHandler}>
        <ProductFullResponsive item={item} productId={productId}>
          {showAddButton && (
            <div className={classes.buttonWrap}>
              <AddToCartWrap item={{
                price: {
                  currencyName: item.colors[0].price.currencyName,
                  price: item.colors[0].price.price.toString(),
                  priceDiscount: item.colors[0].price.priceDiscount.toString(),
                },
                productId: item.colors[0].productId,
                qty: 1,
                sizeId: item.colors[0].sizes[0].sizeId.toString(),
              }} >
                <div className={classes.button}>
                  {t('common.add')}
                </div>
              </AddToCartWrap>
            </div>
          )}
        </ProductFullResponsive>
    </Link>
  );
};

export default ProductItem;
