import React, { PropsWithChildren, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { acCartAddItemAction } from '@actions/acCart';
import { RecommendedForProductContext } from '@context/recomended';
import { ICartItem } from '@interfaces/cart';
import { TThunkDispatch } from '@interfaces/index';
import { IProductInList } from '@interfaces/product';

interface AddToCartWrapProps extends PropsWithChildren {
  cartItemDetail?: IProductInList | null;
  item: ICartItem;
}

const AddToCartWrap: React.FunctionComponent<AddToCartWrapProps> = ({ cartItemDetail, children, item }) => {

  const dispatch: TThunkDispatch = useDispatch<TThunkDispatch>();
  const { recommendedForProduct } = useContext(RecommendedForProductContext);
  const itemToAdd = recommendedForProduct ? { ...item, recommendedForProduct } : item;

  const onAddToCart = useCallback(() => dispatch(acCartAddItemAction(itemToAdd, cartItemDetail)), [dispatch, itemToAdd]);

  if (React.isValidElement(children)) {
    return React.cloneElement(children as React.ReactElement<any>, { onClick: onAddToCart });
  }
  return null;
};

export default AddToCartWrap;
