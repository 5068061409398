import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';

export default makeStyles<Theme>((theme) =>
  createStyles({
    sectionList: {
      '& .swiper-wrapper > .swiper-slide': {
        marginRight: 15,
        width: '20%',
      },
      [theme.breakpoints.down('md')]: {
        '& .swiper-wrapper > .swiper-slide': {
          width: '67%',
        },
        margin: '0 0 0 15px',
        width: 'calc(100% - 15px)',
      },
      margin: '0 -7.5px',
      overflow: 'hidden',
      width: '100%',
    },
  }),
);
