import React from 'react';

import SectionList from '@components/common/SectionList';
import { IProductInList } from '@interfaces/product';

interface ISectionProductsListProps {
  className?: string;
  loaded: boolean;
  products: IProductInList[];
  showAddButton?: boolean;
  slidesPerView?: number;
  wrapperListName: string | JSX.Element;
}

const SectionProductsList: React.FunctionComponent<ISectionProductsListProps> = ({
  className,
  loaded,
  products,
  showAddButton,
  slidesPerView,
  wrapperListName,
}) => {
  return (
    <>
            <SectionList
              loaded={loaded}
              className={className}
              items={products}
              showAddButton={showAddButton}
              slidesPerView={slidesPerView}
              wrapperListName={wrapperListName}
            />
    </>
  );
};

export default SectionProductsList;
