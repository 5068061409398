import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';

export default makeStyles<Theme>((theme) =>
  createStyles({
    imageSkeleton: {
      height: '0',
      marginBottom: 12,
      paddingTop: 'calc(100% * 1.52)',
      width: '100%',
    },
    productListItemContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      margin: '0 4px 31px 4px',
      opacity: 0.4,
      transition: 'all 0.5s ease-out',
    },
    productListItemContainerPreload: {
      opacity: 1,
    },
    productListItemContainerSizeBig: {
      [theme.breakpoints.down('lg')]: {
        width: 'calc(50% - 8px)',
      },
      [theme.breakpoints.down('md')]: {
        width: 'calc(100% - 8px)',
      },
      width: 'calc(33% - 8px)',
    },
    productListItemContainerSizeSlider: {
      width: '100%',
    },
    productListItemContainerSizeSmall: {
      [theme.breakpoints.down('lg')]: {
        width: 'calc(33% - 8px)',
      },
      [theme.breakpoints.down('md')]: {
        width: 'calc(50% - 8px)',
      },
      width: 'calc(25% - 8px)',
    },
    productListItemLink: {
      textDecoration: 'none',
      width: '100%',
    },
  }),
);
