import { IHomepageProductsSliderComponent } from './interfaces';

export const defaultComponent: IHomepageProductsSliderComponent = {
  heading: '',
  productids: [],
  show: true,
  showInApp: true,
  showInMobile: true,
  type: '',
};
